"use strict";

if (document.querySelector('.phi-sessions')) {
  // Function to set a cookie
  var setCookie = function setCookie(name, value, days) {
    var expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = "expires=".concat(expirationDate.toUTCString());
    document.cookie = "".concat(name, "=").concat(value, ";").concat(expires, ";path=/");
  }; // Function to get a cookie
  var getCookie = function getCookie(name) {
    var cookieName = "".concat(name, "=");
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }; //Accordion
  // MENU //
  // Add event listener to body element
  document.querySelector("body").addEventListener("click", function (event) {
    if (event.target.matches(".menu-button")) {
      // Toggle class on clicked element
      console.log('clicked');
      event.target.classList.toggle("menu-on");
      document.body.classList.toggle("active-menu");
    } else if (event.target.matches(".menu-account h3")) {
      // Toggle class on parent element
      event.target.parentElement.classList.toggle("active");
    }
  });

  // Animated Gallery
  // Select all gallery-item elements on the page
  var gridItems = document.querySelectorAll('.gallery-item');
  // Iterate over the gallery-item elements and set their padding
  gridItems.forEach(function (item) {
    // Generate a random padding value (in pixels)
    var padding = Math.floor(Math.random() * 250) + 5;
    // Set the top and bottom padding of the element
    item.style.paddingTop = padding + 'px';
  });

  // Pop Up
  if (document.querySelector('#popup-banner')) {
    var _Cookie$get;
    var togglePopupBanner = function togglePopupBanner() {
      // Toggle the visibility of the pop-up banner and the overlay
      document.querySelector('#popup-banner').classList.toggle('show');
      document.querySelector('#popup-banner-overlay').classList.toggle('show');
      if (!document.querySelector('#popup-banner').classList.contains('show')) {
        Cookie.set('open-popup', 'false', {
          expires: '7D'
        });
      }
    }; // Add a click event listener to the close button
    if (document.querySelector('.close-popup')) {
      document.querySelector('.close-popup').addEventListener('click', togglePopupBanner);
    }
    // Add a click event on the link button
    if (document.querySelector('.register-btn')) {
      document.querySelector('.register-btn').addEventListener('click', togglePopupBanner);
    }

    // Show the pop-up banner when the page loads
    var cookieState = (_Cookie$get = Cookie.get('open-popup')) !== null && _Cookie$get !== void 0 ? _Cookie$get : 'true';
    if (cookieState === 'true') {
      window.addEventListener('load', togglePopupBanner);
    }
  }
  var accordions = document.querySelectorAll('.accordion');
  accordions.forEach(function (accordion) {
    var button = accordion.querySelector('.accordion-button');
    var content = accordion.querySelector('.accordion-content');
    button.addEventListener('click', function () {
      content.classList.toggle('show');
    });
  });

  // Carousel
  document.addEventListener("DOMContentLoaded", function () {
    // Get references to the carousel container and images
    var carousel = document.querySelector(".carousel");
    var images = carousel.querySelectorAll("img");
    var currentIndex = 0;

    // Set the initial opacity of the first image to 1
    images[currentIndex].style.opacity = 1;
    function showImage(index) {
      // Hide all images
      images.forEach(function (image) {
        image.style.opacity = 0;
      });

      // Show the selected image
      images[index].style.opacity = 1;
    }
    function nextImage() {
      currentIndex = (currentIndex + 1) % images.length;
      showImage(currentIndex);
    }

    // Set interval to change images every 4 seconds
    setInterval(nextImage, 9000);
  });

  // Slideshow
  document.addEventListener("DOMContentLoaded", function () {
    // Get references to all slideshow containers and images
    var slideshows = document.querySelectorAll(".slideshow");
    slideshows.forEach(function (slideshow) {
      var images = slideshow.getElementsByTagName("img");
      var currentIndex = 0;
      function showImage(index) {
        // Hide all images
        for (var i = 0; i < images.length; i++) {
          images[i].style.display = "none";
        }

        // Show the selected image
        images[index].style.display = "block";
      }
      function nextImage() {
        currentIndex = (currentIndex + 1) % images.length;
        showImage(currentIndex);
      }

      // Initial display
      showImage(currentIndex);

      // Set interval to rotate images every 4 seconds
      setInterval(nextImage, 6000);
    });
  });

  // Horizontal Scroll
  document.addEventListener("DOMContentLoaded", function () {
    var menuLinks = document.querySelectorAll('.researchspace-menu a');
    var blocks = document.querySelectorAll('.item-container');

    // Check if the device is a mobile device
    var isMobile = window.innerWidth <= 767;
    var thresholdValue = isMobile ? 0.1 : 0.25;
    var observerOptions = {
      root: document.querySelector('.researchspace-container'),
      rootMargin: '0px',
      threshold: thresholdValue
    };
    var observer = new IntersectionObserver(handleIntersection, observerOptions);

    // Observe each block
    blocks.forEach(function (block) {
      observer.observe(block);
    });

    // Handle intersection callback
    function handleIntersection(entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          var targetId = entry.target.id;
          setActiveLink(targetId);
        }
      });
    }

    // Set active link based on target ID
    function setActiveLink(targetId) {
      menuLinks.forEach(function (link) {
        var linkHref = link.getAttribute('href').substring(1);
        link.classList.toggle('active', linkHref === targetId);
      });
    }

    // Smooth scroll to the target section with horizontal scroll
    menuLinks.forEach(function (link) {
      link.addEventListener('click', handleLinkClick);
    });

    // Handle link click event
    function handleLinkClick(e) {
      e.preventDefault();
      var targetId = this.getAttribute('href').substring(1);
      var targetBlock = document.getElementById(targetId);
      if (targetBlock) {
        targetBlock.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
          // Scroll horizontally to the start of the target
          inlineOffset: -80 // Offset for horizontal scroll
        });
      }

      // setTimeout( () => { // scroll of 1px down
      //   window.scrollBy( 0, -1 );
      //   setActiveLink(e.target.getAttribute('href').substring(1));
      // }, 1000 );
    }
  });

  // Active Class
  document.addEventListener("DOMContentLoaded", function () {
    // Get the target element by id
    var targetElement = document.getElementById("hero-header");

    // Options for the IntersectionObserver
    var options = {
      root: null,
      // Use the viewport as the root
      rootMargin: "0px",
      // No margin around the root
      threshold: 0 // Trigger when 50% of the target is visible
    };

    // Callback function to handle intersection changes
    var callback = function callback(entries, observer) {
      entries.forEach(function (entry) {
        var menuButton = document.querySelector('.menu-button');
        var icons = document.querySelector('.icons');
        if (entry.isIntersecting) {
          // Target element is in the viewport
          menuButton.classList.add("menu-button-active");
          icons.classList.add("icons-active");
        } else {
          // Target element is out of the viewport
          menuButton.classList.remove("menu-button-active");
          icons.classList.remove("icons-active");
        }
      });
    };

    // Create an IntersectionObserver
    var observer = new IntersectionObserver(callback, options);

    // Start observing the target element
    observer.observe(targetElement);
  });

  // Call the function fadeInPage()
  fadeInPage();
}