"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.PopupNewsletter = function PopupNewsletter() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Global variables.
   * @private
  */
  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var newsletterPopupTriggers = document.querySelectorAll(".button-open-newsletter");
    if (newsletterPopupTriggers.length) {
      var popup = document.querySelector(".sdd-popup-newsletter");
      var crossPopup = document.querySelector(".popup-newsletter-cross");
      if (popup) {
        newsletterPopupTriggers.forEach(function (trigger) {
          trigger.addEventListener('click', function (e) {
            e.preventDefault();
            popup.classList.add('is-visible');
          });
        });
      }
      if (crossPopup) {
        crossPopup.addEventListener('click', function (e) {
          e.preventDefault();
          var parentElement = crossPopup.closest(".sdd-popup-newsletter");
          parentElement.classList.remove("is-visible");
        });
      }
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();