"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.ScrollTriggerExperience = function ScrollTriggerExperience() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Global variables.
   * @private
   */

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var blockStations = document.querySelector(".stations-container");
    var allStations = document.querySelectorAll(".station-container");
    var progressContainer = document.querySelector(".progress-bars-container");
    var allProgressBars = document.querySelectorAll(".progress-bar-wrapper");
    var allPaginationDot = document.querySelectorAll(".pagination-dot");
    gsap.registerPlugin(ScrollTrigger);
    if (blockStations && window.innerWidth > 600 && progressContainer && allStations.length > 0) {
      var addSection = function addSection(index) {
        var tl = gsap.timeline();
        if (index == 0) {
          tl.fromTo('.progress-bars-container', {
            opacity: 0
          }, {
            opacity: 1,
            duration: .5
          }, index);
          tl.to(".progress-bar-wrapper-".concat(index + 1), {
            width: function width() {
              return widthPerBar * 2;
            },
            duration: .5,
            opacity: 1,
            height: 2
          }, index);
          tl.to(".progress-bar-".concat(index + 1), {
            width: function width() {
              return widthPerBar * 2;
            },
            duration: 2.5
          }, index + 0.5);
          tl.to(".progress-bar-wrapper-".concat(index + 1), {
            width: widthPerBar,
            duration: .5,
            opacity: 1,
            height: 2
          }, index + 3);
          tl.to(".progress-bar-wrapper-".concat(index + 2), {
            width: function width() {
              return widthPerBar * 2;
            },
            duration: .5,
            opacity: 1,
            height: 2
          }, index + 3);
          tl.to(".progress-bar-".concat(index + 1), {
            width: widthPerBar,
            duration: .5
          }, index + 3);
          tl.fromTo(".station-".concat(index + 1), {
            opacity: 0
          }, {
            opacity: 1,
            duration: .5
          }, index + 0.5);
          tl.fromTo(".station-".concat(index + 1), {
            opacity: 1
          }, {
            opacity: 0,
            duration: .5
          }, index + 3);
          tl.fromTo(".img-station-".concat(index + 1), {
            x: 0,
            y: function y(index, target) {
              return (height - target.clientHeight) / 2 + 10;
            }
          }, {
            y: function y(index, target) {
              return (height - target.clientHeight) / 2 - 10;
            },
            duration: 3
          }, index);
          tl.fromTo(".img-station-".concat(index + 1), {
            y: function y(index, target) {
              return (height - target.clientHeight) / 2 - 10;
            },
            opacity: 1,
            width: '100%'
          }, {
            y: 0,
            opacity: .5,
            width: '70%',
            duration: 2,
            zIndex: 1
          }, index + 3);
          tl.fromTo(".img-station-".concat(index + 2), {
            y: height - 400,
            opacity: .5,
            width: '70%'
          }, {
            x: 0,
            y: function y(index, target) {
              return (height - imageFullHeight(target.clientHeight)) / 2 + 10;
            },
            opacity: 1,
            width: '100%',
            duration: 2,
            zIndex: 2
          }, index + 3);
          tl.fromTo(".img-station-".concat(index + 3), {
            y: height,
            opacity: .5
          }, {
            y: height - 300,
            opacity: .5,
            duration: 2
          }, index + 3);
        } else if (allStations.length == index + 1) {
          tl.to(".progress-bar-".concat(index + 1), {
            width: function width() {
              return widthPerBar * 2;
            },
            duration: 3.5
          }, index - 2);
          tl.fromTo(".station-".concat(index + 1), {
            opacity: 0
          }, {
            opacity: 1,
            duration: .5
          }, index - 2);
          tl.fromTo(".station-".concat(index + 1), {
            opacity: 1
          }, {
            opacity: 1,
            duration: 1
          }, index + 3);
          tl.to(".img-station-".concat(index + 1), {
            y: function y(index, target) {
              return (height - imageFullHeight(target.clientHeight)) / 2 - 20;
            },
            duration: 5
          }, index - 2);
        } else {
          var _tl$fromTo;
          tl.to(".progress-bar-".concat(index + 1), {
            width: function width() {
              return widthPerBar * 2;
            },
            duration: 5
          }, index - .5);
          tl.to(".progress-bar-wrapper-".concat(index + 1), {
            width: widthPerBar,
            duration: .5,
            opacity: 1,
            height: 2
          }, index + 5);
          tl.to(".progress-bar-wrapper-".concat(index + 2), {
            width: function width() {
              return widthPerBar * 2;
            },
            duration: .5,
            opacity: 1,
            height: 2
          }, index + 5);
          tl.to(".progress-bar-".concat(index + 1), {
            width: widthPerBar,
            duration: .5
          }, index + 5);
          tl.fromTo(".station-".concat(index + 1), {
            opacity: 0
          }, {
            opacity: 1,
            duration: .5
          }, index - 1);
          tl.fromTo(".station-".concat(index + 1), {
            opacity: 1
          }, {
            opacity: 0,
            duration: .5
          }, index + 4);
          tl.to(".img-station-".concat(index + 1), {
            y: function y(index, target) {
              return (height - target.clientHeight) / 2 - 20;
            },
            duration: 4
          }, index - 1);
          tl.fromTo(".img-station-".concat(index), {
            y: 0
          }, {
            y: '-120%',
            duration: 2
          }, index + 4);
          tl.fromTo(".img-station-".concat(index + 1), {
            opacity: 1,
            width: '100%'
          }, (_tl$fromTo = {
            x: 0,
            y: function y(index, target) {
              return -target.clientHeight / 4;
            }
          }, _defineProperty(_tl$fromTo, "x", '30%'), _defineProperty(_tl$fromTo, "opacity", .5), _defineProperty(_tl$fromTo, "width", '70%'), _defineProperty(_tl$fromTo, "duration", 2), _defineProperty(_tl$fromTo, "zIndex", 1), _tl$fromTo), index + 4);
          tl.fromTo(".img-station-".concat(index + 2), {
            x: '30%',
            y: height - 300,
            width: '70%',
            opacity: .5
          }, {
            x: 0,
            y: function y(index, target) {
              return (height - imageFullHeight(target.clientHeight)) / 2 + 10;
            },
            opacity: 1,
            width: '100%',
            duration: 2,
            zIndex: 2
          }, index + 4);
          tl.fromTo(".img-station-".concat(index + 3), {
            x: '30%',
            y: height,
            opacity: .5
          }, {
            x: '30%',
            y: height - 300,
            opacity: .5,
            duration: 2
          }, index + 4);
        }
        return tl;
      };
      var widthPerBar = Math.round(progressContainer.clientWidth / (allStations.length + 1) - 5);
      assignWidthPerBar(allProgressBars, widthPerBar);
      var height = window.outerHeight;
      var masterTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: ".stations-container",
          start: "top top",
          end: height * (allStations.length + 1) + "-=200px",
          scrub: 1,
          pin: true
        }
      });
      allStations.forEach(function (el, index) {
        masterTimeline.add(addSection(index));
      });
    }
    if (blockStations && window.innerWidth <= 600) {
      var swiperImage = new Swiper(".swiper-image-container", {
        direction: 'horizontal'
      });
      var swiperText = new Swiper(".swiper-text-container", {
        direction: 'horizontal'
      });
      swiperImage.on("slideChangeTransitionStart", function (e) {
        var currentSlideIndex = e.snapIndex + 1;
        var stationPaginationDot = document.querySelector(".pagination-dot-".concat(currentSlideIndex));
        allPaginationDot.forEach(function (dot) {
          dot.classList.remove("active");
        });
        stationPaginationDot.classList.add("active");
      });
      swiperText.on("slideChangeTransitionStart", function (e) {
        var currentSlideIndex = e.snapIndex + 1;
        var stationPaginationDot = document.querySelector(".pagination-dot-".concat(currentSlideIndex));
        allPaginationDot.forEach(function (dot) {
          dot.classList.remove("active");
        });
        stationPaginationDot.classList.add("active");
      });
      swiperText.controller.control = swiperImage;
      swiperImage.controller.control = swiperText;
    }
    return true;
  };

  // Expose public methods & properties

  var assignWidthPerBar = function assignWidthPerBar(element, width) {
    element.forEach(function (bar, index) {
      if (index == 0) {
        bar.style.width = width * 2 + 5 + "px";
      } else {
        bar.style.width = width + "px";
      }
    });
  };
  var imageFullHeight = function imageFullHeight(height) {
    return 100 * height / 70;
  };
  return {
    init: init
  };
}();