"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.SddShuffleLetters = function SddShuffleLetters() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Global variables
   * @private
   */
  var DURATION = 0.25;
  var SHUFFLE_PATTERN = /(?:[\xA9\xAE\u203C\u2049\u2122\u2139\u2194-\u2199\u21A9\u21AA\u231A\u231B\u2328\u2388\u23CF\u23E9-\u23F3\u23F8-\u23FA\u24C2\u25AA\u25AB\u25B6\u25C0\u25FB-\u25FE\u2600-\u2605\u2607-\u2612\u2614-\u2685\u2690-\u2705\u2708-\u2712\u2714\u2716\u271D\u2721\u2728\u2733\u2734\u2744\u2747\u274C\u274E\u2753-\u2755\u2757\u2763-\u2767\u2795-\u2797\u27A1\u27B0\u27BF\u2934\u2935\u2B05-\u2B07\u2B1B\u2B1C\u2B50\u2B55\u3030\u303D\u3297\u3299]|\uD83C[\uDC00-\uDCFF\uDD0D-\uDD0F\uDD2F\uDD6C-\uDD71\uDD7E\uDD7F\uDD8E\uDD91-\uDD9A\uDDAD-\uDDE5\uDE01-\uDE0F\uDE1A\uDE2F\uDE32-\uDE3A\uDE3C-\uDE3F\uDE49-\uDFFA]|\uD83D[\uDC00-\uDD3D\uDD46-\uDE4F\uDE80-\uDEFF\uDF74-\uDF7F\uDFD5-\uDFFF]|\uD83E[\uDC0C-\uDC0F\uDC48-\uDC4F\uDC5A-\uDC5F\uDC88-\uDC8F\uDCAE-\uDCFF\uDD0C-\uDD3A\uDD3C-\uDD45\uDD47-\uDEFF]|\uD83F[\uDC00-\uDFFD])|\r|\n|(?:[\0-\t\x0B\f\x0E-\u2027\u202A-\uD7FF\uE000-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])/g;
  var shuffleHtmlElements = document.querySelectorAll('[data-hover-shuffle]');
  var tw = null;
  var shuffleElements = [];
  var onItemEnterBind = null;
  var onItemLeaveBind = null;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    if (shuffleHtmlElements.length) {
      onItemEnterBind = onItemEnter.bind(this);
      onItemLeaveBind = onItemLeave.bind(this);
      update();
    }
    return true;
  };
  var onItemEnter = function onItemEnter(e) {
    var $el = e.currentTarget;
    var targets = [];
    if ($el.dataset.hoverShuffle == 'children') {
      $el.querySelectorAll('[data-hover-shuffle-child]').forEach(function (child) {
        targets.push(child);
        child.setAttribute('aria-label', child.innerText);
      });
    } else {
      targets = [$el];
      $el.setAttribute('aria-label', $el.innerText);
    }
    tw = gsap.timeline({
      onComplete: function onComplete() {
        targets.forEach(function ($target) {
          $target.innerText = $target.getAttribute('aria-label');
          $target.removeAttribute('aria-label');
        });
      }
    });
    var shufflePerSecond = 4;
    var shuffleDelay = DURATION / shufflePerSecond;
    for (var i = 0; i < shufflePerSecond; i++) {
      var _iterator = _createForOfIteratorHelper(targets),
        _step;
      try {
        var _loop = function _loop() {
          var $target = _step.value;
          tw.add(function () {
            shuffleElementTexts($target, shuffle);
          }, shuffleDelay * i);
        };
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
  };
  var onItemLeave = function onItemLeave(e) {
    tw && tw.kill();
    var $el = e.currentTarget;
    var targets = [];
    if ($el.dataset.hoverShuffle == 'children') {
      $el.querySelectorAll('[data-hover-shuffle-child]').forEach(function (child) {
        targets.push(child);
      });
    } else {
      targets = [$el];
    }
    targets.forEach(function ($target) {
      if ($target.getAttribute('aria-label')) $target.innerText = $target.getAttribute('aria-label');
      $target.removeAttribute('aria-label');
    });
  };
  var reset = function reset() {
    shuffleElements.forEach(function (el) {
      el.removeEventListener('mouseenter', onItemEnterBind);
      el.removeEventListener('mouseleave', onItemLeaveBind);
    });
    shuffleElements = [];
  };
  var update = function update() {
    reset();
    document.querySelectorAll('[data-hover-shuffle]').forEach(function (el) {
      el.addEventListener('mouseenter', onItemEnterBind);
      el.addEventListener('mouseleave', onItemLeaveBind);
      shuffleElements.push(el);
    });
  };

  // Utils - String
  var shuffle = function shuffle(input) {
    var a = typeof input == 'string' ? input.split("") : input,
      n = a.length;
    for (var i = n - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var tmp = a[i];
      a[i] = a[j];
      a[j] = tmp;
    }
    return a.join("");
  };

  // Utils - HTML
  var shuffleElementTexts = function shuffleElementTexts(item, shuffleFn) {
    if ((!item.children.length || item.dataset.allowShuffle != undefined) && item.innerText) {
      // Remove hidden content
      item.querySelectorAll('.u-screen-reader-text').forEach(function (item) {
        return item.remove();
      });
      // Wrap line breaks with spaces
      var words = item.innerText.replace('\n', ' \n ').split(' ');
      // Actually shuffle each "word"
      for (var i = 0; i < words.length; i++) {
        var chars = _toConsumableArray(words[i].matchAll(SHUFFLE_PATTERN)).map(function (item) {
          return item[0];
        });
        words[i] = shuffleFn(chars);
      }
      // Output result
      item.innerText = words.join(' ');
    }
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();