"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.MagicScroll = function MagicScroll() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Global variables.
   * @private
   */

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var blockStations = document.querySelector("#stations");
    if (blockStations) {
      gsap.registerPlugin(ScrollTrigger);
      var masterTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: ".stations-wrapper",
          anticipatePin: true,
          start: "top top",
          end: window.innerWidth < 768 ? "+=" + window.innerHeight * 3 : "+=" + window.innerHeight * 4,
          scrub: 1,
          pin: true
        }
      });

      // Depends if mobile or desktop
      if (window.innerWidth < 768) {
        masterTimeline.timeScale(0.2);
      } else {
        masterTimeline.timeScale(0.5);
      }
      masterTimeline.from(".stations-images-container", {
        y: "0",
        opacity: 0.5,
        duration: window.innerWidth < 768 ? 0.3 : 0.4,
        ease: "none"
      }, 0).to(".stations-images-container", {
        y: "-50%",
        opacity: 0.5,
        duration: 1
      }, 2.4).from(".stations-items.items-1", {
        y: "100%",
        opacity: 0,
        duration: 0.5,
        ease: "none"
      }, 0.5).to(".stations-items.items-1", {
        y: "-100%",
        opacity: 0,
        duration: 0.5,
        ease: "none"
      }, 1).from(".stations-items.items-2", {
        y: "100%",
        opacity: 0,
        duration: 0.5,
        ease: "none"
      }, 1.4).to(".stations-items.items-2", {
        y: "-50%",
        opacity: 0,
        duration: 0.5,
        ease: "none"
      }, 1.9);
      window.addEventListener("resize", function () {
        masterTimeline.scrollTrigger.refresh();
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();