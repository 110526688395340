"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Research = function Research() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;

    // Your code here
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    if (document.querySelector('.research-space') || document.querySelector('.journal') || document.querySelector('.references-listing')) {
      if (urlParams.get('typeId') || urlParams.get('date') || urlParams.get('subjectId') || urlParams.get('authorId')) {
        document.querySelector('#entry-filters').scrollIntoView();
      }
    }
    var $uploadFields = document.querySelectorAll(".limit-upload");
    if ($uploadFields) {
      $uploadFields.forEach(function ($uploadField) {
        $uploadField.onchange = function () {
          if (this.files[0].size > 8388608) {
            if (site.App.config('locale') == 'fr') {
              alert("Le fichier est trop gros! Le fichier doit être plus petit que 8mo");
            } else {
              alert("File is too big! It has to be lower than 8mb");
            }
            this.value = "";
          }
        };
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();