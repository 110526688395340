"use strict";

window.site = window.site || {};

/**
 * Manage forms and fields
 * @class Forms
 * @static
 */
site.Forms = function Forms() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Global variables
   * @private
   */
  var $forms = document.querySelectorAll('.form-wrapper');

  // SVG animated loader
  var loader = '<svg width="40" height="10" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#fff">' + '<circle cx="15" cy="15" r="15">' + '<animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"/>' + '<animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite"/>' + '</circle>' + '<circle cx="60" cy="15" r="9" fill-opacity="0.3">' + '<animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9" calcMode="linear" repeatCount="indefinite"/>' + '<animate attributeName="fill-opacity" from="0.5" to="0.5" begin="0s" dur="0.8s" values=".5;1;.5" calcMode="linear" repeatCount="indefinite"/>' + '</circle>' + '<circle cx="105" cy="15" r="15">' + '<animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"/>' + '<animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite"/>' + '</circle>' + '</svg>';

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    $forms.forEach(function (form) {
      // Validate fields
      validateFields(form);
      console.log('here');

      // Submit form
      form.addEventListener('submit', function (e) {
        e.preventDefault();
        submitForm(form);
      });
    });
    return true;
  };

  /**
   * Validate fields
   * @private
   */
  var validateFields = function validateFields(form) {
    var $fields = form.querySelectorAll('input, select, textarea');
    $fields.forEach(function (field) {
      if (field.tagName === 'INPUT' || field.tagName === 'TEXTAREA') {
        field.addEventListener('input', function (e) {
          validateRequiredField(field);
          validatePhoneField(field);
          validateZipCodeField(field);
          validateUrlField(field);
        });
      } else if (field.tagName === 'SELECT') {
        field.addEventListener('change', function (e) {
          validateRequiredField(field);
        });
      }
    });
  };

  /**
   * Validate required field
   * @private
   */
  var validateRequiredField = function validateRequiredField(field) {
    if (field.required) {
      toggleErrorMessage(field, field.value !== '', 'Le champ est requis');
    }
  };

  /**
   * Validate phone field
   * @private
   */
  var validatePhoneField = function validatePhoneField(field) {
    if (field.getAttribute('mask') === 'phone' && field.value !== '') {
      var validPhoneRegex = /^\(\d{3}\)\s\d{3}-\d{4}$/;
      var x = field.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      field.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      toggleErrorMessage(field, validPhoneRegex.test(field.value), 'Le numéro de téléphone n\'est pas valide<br>Exemple: (514) 555-5555');
    }
  };

  /**
   * Validate zip code field
   * @private
   */
  var validateZipCodeField = function validateZipCodeField(field) {
    if (field.getAttribute('mask') === 'zipCode' && field.value !== '') {
      var validZipCodeRegex = /^[A-Z][0-9][A-Z]\s[0-9][A-Z][0-9]$/;
      toggleErrorMessage(field, validZipCodeRegex.test(field.value), 'Le code postal n\'est pas valide<br>Exemple: H0H 0H0');
    }
  };

  /**
   * Validate url field
   * @private
   */
  var validateUrlField = function validateUrlField(field) {
    if (field.getAttribute('mask') === 'url' && field.value !== '') {
      var isValid;
      try {
        var newUrl = new URL(field.value);
        isValid = newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
      } catch (err) {
        isValid = false;
      }
      console.log(isValid);
      toggleErrorMessage(field, isValid, 'L\'URL n\'est pas valide<br>Exemple: https://google.ca');
    }
  };

  /**
   * Toggle error message
   * @private
   */
  var toggleErrorMessage = function toggleErrorMessage(field, isValid, text) {
    var $errorMessage = field.parentNode.querySelector('.error-message');
    var $submitButton = field.closest('form').querySelector('button[type="submit"]');
    console.log($submitButton);
    if ($errorMessage && $submitButton) {
      $errorMessage.classList.toggle('is-active', !isValid);
      $errorMessage.innerHTML = text;
      $submitButton.disabled = !isValid;
    }
  };

  /**
   * Disable submit button during form validation
   * change button text by a svg animated loader
   * by checking if there are any error messages
   * if all good then submit the form, if not put back the button text
   *
   * @private
   */
  var submitForm = function submitForm(form) {
    var $errorMessage = form.querySelector('.error-message.is-active');
    var $submitButton = form.querySelector('button[type="submit"]');
    if ($submitButton) {
      $submitButton.disabled = true;
      $submitButton.innerHTML = loader;
      if ($errorMessage) {
        $submitButton.innerHTML = 'Confirmer';
      } else {
        form.submit();
      }
    }
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();