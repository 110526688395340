"use strict";

window.site = window.site || {};

/**
 * Utilities for Resize height on iframe
 * @class Track
 * @static
 */
site.MaxIframeResize = function MaxIframeResize() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Global variables
   * @private
   */

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var iframe = document.getElementById('max-embed');
    if (window.visualViewport && iframe != null) {
      var resizeHandler = function resizeHandler() {
        iframe.style.height = window.visualViewport.height + 'px';
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'auto'
        });
      };
      resizeHandler();
      window.visualViewport.addEventListener('resize', resizeHandler);
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();