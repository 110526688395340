"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.SddHeader = function SddHeader() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Global variables.
   * @private
  */
  var promoBanner = document.querySelector('.sdd-promo-banner');
  var header = document.querySelector('.sdd-header');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    if (promoBanner && header) {
      var promoBannerHeight = promoBanner.offsetHeight;
      var logo = header.querySelector('.sdd-header-logo');
      if (window.pageYOffset < promoBannerHeight) {
        header.style.transform = "translateY(".concat(promoBannerHeight, "px)");
      } else {
        var logoStickySrc = logo.getAttribute('data-logo-sticky-src');
        logo.setAttribute('src', logoStickySrc);
      }
      window.addEventListener('scroll', function () {
        if (window.pageYOffset < promoBannerHeight) {
          header.style.transform = "translateY(".concat(promoBannerHeight, "px)");
          var logoSrc = logo.getAttribute('data-logo-src');
          logo.setAttribute('src', logoSrc);
        } else {
          header.style.transform = "translateY(0)";
          var _logoStickySrc = logo.getAttribute('data-logo-sticky-src');
          logo.setAttribute('src', _logoStickySrc);
        }
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();