"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.SddGlobal = function SddGlobal() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Global variables.
   * @private
  */
  var promoBanner = document.querySelector('.sdd-promo-banner');
  var header = document.querySelector('.sdd-header');
  var heroPanel = document.querySelector('.sdd-hero-panel');
  var typeWritingElements = document.querySelectorAll('[data-texts-typewriting]');
  var mediaSliders = document.querySelectorAll('[data-media-slider]');
  var lastScrollPosition = 0;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    if (header) {
      var logo = header.querySelector('.header-logo');
      window.addEventListener('scroll', onScroll);
      if (promoBanner) {
        var promoBannerHeight = promoBanner.offsetHeight;
        if (window.pageYOffset < promoBannerHeight) {
          header.style.transform = "translateY(".concat(promoBannerHeight, "px)");
        } else {
          var logoStickySrc = logo.getAttribute('data-logo-sticky-src');
          logo.setAttribute('src', logoStickySrc);
          logo.classList.add('white');
        }
        window.addEventListener('scroll', function () {
          if (window.pageYOffset < promoBannerHeight) {
            header.style.transform = "translateY(".concat(promoBannerHeight, "px)");
            var logoSrc = logo.getAttribute('data-logo-src');
            logo.setAttribute('src', logoSrc);
            logo.classList.remove('white');
          } else {
            header.style.transform = "translateY(0)";
            var _logoStickySrc = logo.getAttribute('data-logo-sticky-src');
            logo.setAttribute('src', _logoStickySrc);
            logo.classList.add('white');
          }
        });
        heroPanel.style.height = "calc((100vh + 90px) - ".concat(promoBannerHeight, "px)");
      } else {
        if (window.pageYOffset > 0) {
          var _logoStickySrc2 = logo.getAttribute('data-logo-sticky-src');
          logo.setAttribute('src', _logoStickySrc2);
          logo.classList.add('white');
        }
        window.addEventListener('scroll', function () {
          if (window.pageYOffset > 0) {
            header.style.transform = "translateY(0)";
            var _logoStickySrc3 = logo.getAttribute('data-logo-sticky-src');
            logo.setAttribute('src', _logoStickySrc3);
            logo.classList.add('white');
          } else {
            header.style.transform = "translateY(0)";
            var logoSrc = logo.getAttribute('data-logo-src');
            logo.setAttribute('src', logoSrc);
            logo.classList.remove('white');
          }
        });
      }
      if (heroPanel) {
        // Check if the window is mobile
        if (window.innerWidth < 768) {
          // Get iframe element
          var iframe = heroPanel.querySelector('iframe');
          if (iframe) {
            var iframeSrc = iframe.getAttribute('data-src-mobile');
            iframe.setAttribute('src', iframeSrc);
          }

          // Get data-src-bg-ImageMobile attribute
          var bgImageMobile = heroPanel.getAttribute('data-src-bgImgMobile');
          if (bgImageMobile) {
            heroPanel.style.backgroundImage = "url(".concat(bgImageMobile, ")");
          }
        }
        window.addEventListener('resize', function () {
          if (promoBanner) {
            var _promoBannerHeight = promoBanner.offsetHeight;
            heroPanel.style.height = "calc((100vh + 90px) - ".concat(_promoBannerHeight, "px)");
          }
          var iframe = heroPanel.querySelector('iframe');

          // Check if the window is mobile
          if (iframe) {
            if (window.innerWidth < 768 && iframe.getAttribute('data-src-mobile') !== iframe.getAttribute('src')) {
              var _iframeSrc = iframe.getAttribute('data-src-mobile');
              iframe.setAttribute('src', _iframeSrc);
            } else if (window.innerWidth >= 768 && iframe.getAttribute('data-src') !== iframe.getAttribute('src')) {
              var _iframeSrc2 = iframe.getAttribute('data-src');
              iframe.setAttribute('src', _iframeSrc2);
            }
          }

          // Get data-src-bg-ImageMobile attribute
          var bgImageMobile = heroPanel.getAttribute('data-src-bgImgMobile');
          var bgImage = heroPanel.getAttribute('data-src-bgImg');
          if (bgImageMobile && bgImage) {
            if (window.innerWidth < 768 && heroPanel.style.backgroundImage !== "url(".concat(bgImageMobile, ")")) {
              heroPanel.style.backgroundImage = "url(".concat(bgImageMobile, ")");
            } else if (window.innerWidth >= 768 && heroPanel.style.backgroundImage !== "url(".concat(bgImage, ")")) {
              heroPanel.style.backgroundImage = "url(".concat(bgImage, ")");
            }
          }
        });
      }
    }
    if (promoBanner) {
      var promoBlock = document.querySelector('.sdd-newsletter');
      if (promoBlock) {
        promoBanner.addEventListener('click', function () {
          var topPos = promoBlock.getBoundingClientRect().top + window.pageYOffset - 40;
          window.scrollTo({
            top: topPos,
            behavior: 'smooth'
          });
        });
      }
    }

    // Mobile menu toggle
    var $mobileMenu = document.querySelector('.mobile-header-container');
    var $mobileMenuToggle = document.getElementById('mobile-menu-toggle');
    if ($mobileMenu && $mobileMenuToggle) {
      $mobileMenuToggle.addEventListener('change', function () {
        if ($mobileMenuToggle.checked) {
          $mobileMenu.classList.add('is-active');
        } else {
          $mobileMenu.classList.remove('is-active');
        }
      });
    }
    if (typeWritingElements.length > 0) {
      typeWritingElements.forEach(function (element, index) {
        var textElement = element.querySelector('p.text');
        var parentElement = element.closest('.talk-with-maxx');
        if (textElement) {
          var text1 = textElement.getAttribute('data-text-primary');
          var text2 = textElement.getAttribute('data-text-secondary');
          if (isInViewport(parentElement)) {
            if (!textElement.classList.contains('is-animated')) {
              textElement.classList.add('is-animated');
              element.classList.add('has-expand-animation');
              element.classList.remove('has-shrink-animation');
              if (index === 1) {
                element.style.animationDelay = '2s';
              }
              var typewriter = new Typewriter(textElement, {
                delay: 75,
                loop: false,
                cursor: '',
                autoStart: true
              });
              if (index === 1) {
                typewriter.pauseFor(2500);
              }
              typewriter.typeString(text1).pauseFor(2500).deleteAll().pauseFor(2500).typeString(text2).pauseFor(2500).start();
            }
          }
          window.addEventListener('scroll', function () {
            if (isInViewport(parentElement)) {
              if (!textElement.classList.contains('is-animated')) {
                textElement.classList.add('is-animated');
                element.classList.add('has-expand-animation');
                element.classList.remove('has-shrink-animation');
                if (index === 1) {
                  element.style.animationDelay = '2s';
                }
                var _typewriter = new Typewriter(textElement, {
                  delay: 75,
                  loop: false,
                  cursor: '',
                  autoStart: true
                });
                if (index === 1) {
                  _typewriter.pauseFor(2500);
                }
                _typewriter.typeString(text1).pauseFor(2500).deleteAll().pauseFor(2500).typeString(text2).pauseFor(2500).start();
              }
            } else {
              if (textElement.classList.contains('is-animated')) {
                textElement.classList.remove('is-animated');
                element.classList.remove('has-expand-animation');
                element.classList.add('has-shrink-animation');
              }
            }
          });
        }
      });
    }
    if (mediaSliders.length > 0) {
      mediaSliders.forEach(function (element) {
        var mediaSlider = new Flickity(element, {
          freeScroll: true,
          cellAlign: 'left',
          contain: true,
          prevNextButtons: false,
          pageDots: false,
          wrapAround: true,
          selectedAttraction: 0.01,
          friction: 0.15,
          selectedIndex: 2,
          imagesLoaded: true
        });
      });
    }
    var iframeMaxx = document.querySelector('.sdd-maxx-iframe');
    if (iframeMaxx) {
      window.addEventListener('message', function (event) {
        //detect message from iframe
        if (event.origin !== "http://max.phi.ca" && event.origin !== "https://max.phi.ca") return;
        if (header) {
          var homeLocation = header.querySelector('.header-logo-link');
          if (homeLocation) {
            window.location.href = homeLocation.href;
          }
        }
      });
    }
    return true;
  };
  var isInViewport = function isInViewport(element) {
    var rect = element.getBoundingClientRect();
    return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
  };
  var onScroll = function onScroll() {
    var currentScrollPosition = window.pageYOffset;
    if (currentScrollPosition > lastScrollPosition + 2) {
      // Scroll down
      header.classList.add('is-hidden');
    } else if (currentScrollPosition === 0) {
      // Scroll to top
      header.classList.remove('is-hidden');
    } else {
      // Scroll up
      header.classList.remove('is-hidden');
    }
    lastScrollPosition = currentScrollPosition;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();