"use strict";

var element = document.querySelector('.shuffle');
// get the original HTML of the element
if (element) {
  var shuffleArray = function shuffleArray(array) {
    // shuffle the array of words using the Fisher-Yates algorithm
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var _ref = [array[j], array[i]];
      array[i] = _ref[0];
      array[j] = _ref[1];
    }
    return array;
  };
  var originalHTML = element.innerHTML;

  // add a hover event listener to the element that triggers the animation
  element.addEventListener('mouseenter', function () {
    // split the original HTML into an array of words
    var words = originalHTML.split(' ');

    // start the shuffle animation
    var shuffleInterval = setInterval(function () {
      // shuffle the array of words
      var shuffledWords = shuffleArray(words);

      // update the element's HTML with the shuffled words
      element.innerHTML = shuffledWords.join(' ');
    }, 100); // update the words every 80ms

    // add a mouseleave event listener to the element that stops the animation
    element.addEventListener('mouseleave', function () {
      // stop the shuffle animation
      clearInterval(shuffleInterval);

      // update the element's HTML with the original HTML
      element.innerHTML = originalHTML;
    });
  });
}