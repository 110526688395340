"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Popup = function Popup() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Global variables.
   * @private
  */
  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var allCardPopup = document.querySelectorAll(".has-popup");
    var allCrossPopup = document.querySelectorAll(".cross-close-popup");
    var header = document.querySelector('.sdd-header');
    if (allCardPopup) {
      allCardPopup.forEach(function (card) {
        card.addEventListener('click', function () {
          var popup = card.querySelector(".popup-container");
          popup.classList.add("is-visible");
          header.style.zIndex = 1;
        });
      });
    }
    if (allCrossPopup) {
      allCrossPopup.forEach(function (cross) {
        cross.addEventListener('click', function (e) {
          e.stopPropagation();
          var parentElement = cross.closest(".popup-container");
          parentElement.classList.remove("is-visible");
          header.style.zIndex = 100;
        });
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();