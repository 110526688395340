"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Accordeon = function Accordeon() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Global variables.
   * @private
  */
  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var allAccordeon = document.querySelectorAll(".block-accordeon");
    if (allAccordeon) {
      allAccordeon.forEach(function (accordeon) {
        var elementClick = accordeon.querySelector(".question-title");
        elementClick.addEventListener('click', function () {
          accordeon.classList.toggle("is-active");
        });
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();